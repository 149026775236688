/* @import 'react-resizable/css/styles.css'; */
@import './font.css';
@import './grid.css';
@import './animations.css';
@import './top-ledger.css';
@import './typography.css';
@import './theme-variables.css';
@import '../components/Tooltip/Tooltip.css';
@import '../components/Carousel/styles/carousel.css';
@import '../components/MayanWidget/mayan.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Theme */
html {
	font-size: 16px;
}

* {
	outline: none;
}

:root {
	/* Variables here are used by both dark and light theme */

	--primary: theme('colors.yellow.50');
	--stat-1: theme('colors.dark-theme.stat-1');
	--stat-2: theme('colors.dark-theme.stat-2');
	--stat-3: theme('colors.dark-theme.stat-3');

	--black: theme('colors.black');
	--prize-gradient: theme('backgroundImage.prize-gradient');
	--input-border-hover: theme('backgroundImage.primary-gradient');
	--alpha-program-gradient: theme('backgroundImage.alpha-program-gradient');
	--icon-default: theme('colors.darkBlue.20');
	--icon-selected: theme('colors.darkBlue.50');
	--meme-bg: #fdf8f5;

	/*
	* Font weights have been intentionally reduced by 1 level, to visually match the Figma design system.
	* i.e. Heading font weight in Figma is 600, but in code it will be 500.
	*
	* Unsure of why there is a discrepancy between the two font systems.
	*/
	--heading-font-weight: 500;
	--title-font-weight: 400;
	--body-font-weight: 300;

	--vaults-bg-gradient: linear-gradient(
		45deg,
		#00d9ff 0%,
		#00d9ff 8%,
		#6de9ff 17%,
		#b58aff 41%,
		#a18bff 54%,
		#b58aff 69%,
		#6de9ff 84%,
		#00d9ff 88%,
		#00d9ff 100%
	);

	--fuel-page-background-gradient: linear-gradient(
		180deg,
		rgba(3, 10, 19, 0.3) 0%,
		rgba(6, 0, 139, 0.3) 34%,
		rgba(30, 14, 134, 0.3) 56%,
		rgba(49, 45, 145, 0.3) 78%,
		rgba(81, 104, 188, 0.3) 100%
	);
}

/******************************************************************************************************/
/* Manual Theme Variables   																																					*/
/* These variables are not from figma, and we need them to be differentiated for dark and light mode	*/
/******************************************************************************************************/
.light-theme {
	/* To use this gradient for icons, ensure that the linear gradient is set up in _app.tsx */
	--app-gradient: theme('backgroundImage.light-app-gradient');
	--fuel-gradient: theme('backgroundImage.light-fuel-gradient');
	--swift-gradient: theme('backgroundImage.light-swift-gradient');
	--negative-bg-gradient: theme(
		'backgroundImage.negative-bg-gradient-light-theme'
	);
	--warning-bg-gradient: theme(
		'backgroundImage.warning-bg-gradient-light-theme'
	);
	--drift-earn-gradient: linear-gradient(
		160deg,
		#00295f 0%,
		#fded5d 10%,
		#d867ea 25%,
		#0ab8df 50%,
		#d867ea 75%,
		#fded5d 90%,
		#00295f 100%
	);
}

.dark-theme {
	/* To use this gradient for icons, ensure that the linear gradient is set up in _app.tsx */
	--app-gradient: theme('backgroundImage.dark-app-gradient');
	--fuel-gradient: theme('backgroundImage.dark-fuel-gradient');
	--swift-gradient: theme('backgroundImage.dark-swift-gradient');
	--negative-bg-gradient: theme(
		'backgroundImage.negative-bg-gradient-dark-theme'
	);
	--warning-bg-gradient: theme(
		'backgroundImage.warning-bg-gradient-dark-theme'
	);
	--drift-earn-gradient: linear-gradient(
		160deg,
		#0ab8df 10%,
		#d867ea 68%,
		#fded5d 75%,
		#00295f 90%
	);
}

/******************************************************************************************************/
/* CSS Classes						   																																					*/
/******************************************************************************************************/

/* Base */
html {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
	/* WebKit */
	width: 0;
	height: 0;
	background-color: var(--container-bg);
}

html::-webkit-scrollbar-thumb {
	background-color: var(--scroll-bar);
}

html::-webkit-scrollbar-corner {
	background-color: var(--container-bg);
}

body {
	@apply text-sm font-body;
	overflow-x: hidden;
	font-feature-settings: 'tnum', 'lnum';
}

.monospace-off {
	font-feature-settings: normal;
}

a {
	@apply transition-all duration-300 text-interactive-link hover:opacity-70;
}

a.skipHoverOpacity {
	@apply hover:opacity-100;
}

button {
	letter-spacing: inherit;
	transition: opacity 0.2s ease-in-out;
	-moz-transition: opacity 0.2s ease-in-out;
	-webkit-transition: opacity 0.2s ease-in-out;
}

.default-transition {
	@apply transition-all duration-300;
}

/* <region> Hide up and down arrows from number inputs */
/* ## Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
/* ## Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
/* </region> */

/* Chart */

.TVChartContainer {
	height: 100%;
	width: 100%;
}

.tradingview-chart {
	display: contents;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	z-index: 100;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.right-align {
	margin-left: auto;
}

.grid-panel-header-tabs > div {
	padding: 10px;
}

.grid-container {
	padding: 5px;
}

/* Scrollbars */

.thin-scroll::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: var(--container-bg);
}

.thin-scroll::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background-color: var(--scroll-bar);
}

.thin-scroll::-webkit-scrollbar-track {
	background-color: inherit;
}

.thin-scroll::-webkit-scrollbar-corner {
	background-color: var(--container-bg);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hidden-scroll::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hidden-scroll {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.price_header_right_side {
	display: flex;
	flex-direction: row;
}

#fundingRateInfo h1 {
	font-size: 26px;
	font-weight: bold;
	line-height: 1;
}

.asset-dropdown {
	position: absolute;
	top: 96px;
	left: 10px;
}

.mobile-trade-direction {
	display: none;
}

details {
	position: relative;
	cursor: pointer;
}
details > summary:before {
	height: 8px;
	width: 8px;
	content: ' ';
	display: block;
	position: absolute;
	transform: rotate(-45deg);
	border-bottom: 2px solid #222f40;
	border-right: 2px solid #222f40;
	left: 0px;
	top: 3px;
}
details[open] > summary:before {
	height: 8px;
	width: 8px;
	content: ' ';
	display: block;
	position: absolute;
	border-bottom: 2px solid #222f40;
	border-right: 2px solid #222f40;
	transform: rotate(45deg);
	left: 0px;
	top: 3px;
}
.marginInfoItem {
	padding-left: 15px;
}
.marginInfoItem ul {
	margin-left: -15px;
}
.marginInfoItem ul li:first-child:before {
	height: 10px;
	top: 5px;
}
.marginInfoItem ul li:before {
	content: ' ';
	width: 15px;
	height: 26px;
	border-bottom: 1px solid #1e2f42;
	border-left: 1px solid #1e2f42;
	display: block;
	position: absolute;
	top: -16px;
	left: -20px;
}

.portfolio-graph {
	height: 400px;
}

@media screen and (max-width: 852px) {
	#tvChart {
		width: 100% !important;
		min-height: 400px !important;
		transform: none !important;
	}
}

@media screen and (max-width: 760px) {
	/* DO THIS TO PREVENT MOBILE OS' FROM ZOOMING IN ON TEXT FIELDS WHEN SELECTED */
	input,
	select,
	textarea {
		font-size: 16px !important;
	}

	.grid-container div .main {
		height: 100vh;
	}

	.react-grid-layout {
		height: unset;
		height: 100% !important;
		/*overflow-y: scroll !important;*/
		/* margin-top: 48px; */
	}

	/*	#assetSelector,
	.slippageSection {
		display: none !important;
	}*/

	#fundingRateInfo,
	#tvChart,
	#tradeForm,
	#recentTrades,
	#marginInfo,
	#userInfo {
		transform: none !important;
		width: 100% !important;
		height: unset !important;
		position: relative !important;
	}

	#topbar {
		position: relative;
		top: 0;
		left: 0;
		width: 100%;
		height: 48px;
		z-index: 100;
	}

	.navbar-connect-wallet-button {
		position: relative;
		top: 1px;
		right: 0;
	}

	.settings-button {
		height: 32px;
		padding: 0 !important;
	}

	.subheader {
		display: none;
	}

	.changemarket {
		z-index: 10;
		height: 40px;
		width: 100%;
		top: 96px;
		left: 0;
	}

	.change-market-inner {
		height: 40px;
		color: white;
	}

	.funding-rate-container {
		height: 48px;
		margin-top: 0;
		top: 48px;
		left: 0;
		width: 100%;
	}

	.change-market-inner,
	.funding-rate-container {
		border-radius: 0 !important;
		border: 0px solid transparent !important;
	}

	.fixed-5-width {
		max-width: 5rem;
		min-width: 5rem;
	}

	.open-interest-label,
	.daily-volume-label {
		font-size: 10px;
		margin: 0 0 4px 0;
		padding: 0;
	}

	.open-interest-text {
		line-height: 1;
		padding: 0;
		color: var(--darkBlue-30);
	}

	.price_header_right_side {
		flex-direction: column;
	}

	#tvChart {
		height: 400px !important;
		width: 100% !important;
		top: 80px !important;
		z-index: 9;
	}

	#tradeForm {
		z-index: 8;
		height: 100% !important;
		top: 80px !important;
	}

	.slider-button,
	.slider-toggle {
		display: none !important;
	}

	.drift-ui-popup .rc-slider {
		margin-top: 16px;
	}

	.drift-ui-popup .rc-slider-track,
	.drift-ui-popup .rc-slider-rail {
		top: -4px;
	}

	.drift-ui-popup {
		top: -30px !important;
		height: 24px !important;
	}

	.drift-ui-popup {
		height: 22px !important;
	}

	.drift-ui-popup .close-position-button {
		margin-top: 64px !important;
	}

	#recentTrades {
		z-index: 7;
		top: -816px !important;
		height: 100% !important;
		overflow: hidden;
	}

	.recent-trades-header {
		position: sticky;
		top: 0;
		padding: 0 32px;
	}

	#marginInfo {
		z-index: 6;
		top: 78px !important;
		height: 256px !important;
	}

	#userInfo {
		z-index: 5;
		top: 76px !important;
	}

	.buyandsellcontaniner,
	.topcontainer {
		display: block;
	}

	.buyandsell,
	.chartui {
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
	}

	.chartui {
		z-index: 10;
		width: 100%;
		top: 136px;
		left: 0;
		height: 360px;
	}

	.buyandsell {
		z-index: 9;
		width: 100%;
		top: 496px;
		left: 0;
		padding: 8px;
	}

	.chartinfo {
		position: absolute;
		top: 840px;
		left: 0;
		width: 100%;
	}

	.margin-account-section,
	.deposit-withdaw-button {
		display: none;
	}

	.recenttrades svg.mr-1\.5 {
		display: none;
	}

	.connectwallet {
		left: 0;
		top: 1176px;
		min-height: 320px;
		width: 100%;
		background-color: transparent;
	}

	.mobile-trade-direction {
		display: block;
		position: relative;
		margin-left: 22px;
		font-size: 14px;
	}

	.mobile-trade-direction::before {
		content: '';
		position: absolute;
		border-left: 2px solid #a1a1aa;
		height: 16px;
		opacity: 0.25;
		left: -12px;
		top: -2px;
	}

	/*Targeting header of Positions table here to ensure that an empty Positions card doesn't appear on mobile*/
	.positions-content-header,
	.positions-table-header,
	.footer,
	.desktop-trade-direction {
		display: none !important;
	}

	.modal-overlay {
		background: hsla(200, 4%, 4%, 0.85);
		z-index: 101 !important;
		position: fixed !important;
	}

	.asset-dropdown {
		position: absolute;
		top: 96px;
		left: -16px;
		width: calc(100% + 32px);
		height: 496px;
		z-index: 1;
		border: 0;
		overflow-y: visible;
		background: transparent;
	}

	.asset-dropdown-header {
		height: 40px !important;
	}

	.topchartinfo {
		display: none;
	}

	.topchartinfo .marginInfoItem:nth-child(3),
	.topchartinfo .marginInfoItem:nth-child(4),
	.topchartinfo .marginInfoItem:nth-child(7),
	.topchartinfo .marginInfoItem:nth-child(8) {
		display: none;
	}

	details[open] > summary::before,
	.marginInfoItem ul li::before {
		display: none !important;
	}

	.marginInfoItem {
		padding-left: 16px;
	}

	.topchartinfo .marginInfoItem:first-child {
		margin-top: 16px;
	}

	.margin-info-title {
		height: 16px;
		margin: 0 0 16px 16px;
		padding: 0;
	}

	.chartinfo {
		display: block;
	}

	.drift-ui-popup-container {
		position: relative;
	}

	body {
		height: 100%;
		height: 100vh;
		width: 100%;
	}

	#portSummary {
		display: block;
		position: unset !important;
		border: 0px solid transparent;
		transform: none !important;
		width: 100% !important;
		height: 464px !important;
	}

	#portSummary .recent-trades-title,
	.portfolio-type-tab {
		display: none;
	}

	#portSummary .grid-panel-header-tabs div {
		padding: 0;
		margin: 0;
	}

	#portSummary .grid-panel-header-tabs div a {
		padding: 10px 16px;
		text-transform: uppercase;
		font-size: 10px;
		font-weight: 400;
		height: 40px;
	}

	#portSummary .grid-panel-header-tabs {
		margin-right: 0;
	}

	#portSummary .grid-panel-header-tabs div {
		width: 32px;
	}

	#portSummary .grid-panel-header-tabs div:not(:last-child) {
		margin-right: 8px;
	}

	#depositsWithdrawals {
		position: relative !important;
		transform: none !important;
		height: unset !important;
		width: unset !important;
	}

	#userInfo,
	#balanceInfo {
		display: none;
	}

	.drift-portfolio-page-section {
		overflow-x: hidden !important;
		overflow-y: auto !important;
	}

	#depositsWithdrawals {
		margin-bottom: 72px;
	}

	.portfolio-graph {
		height: 320px;
		overflow: none !important;
		border: 0px solid transparent;
	}
}

/* orderbook flash */

@keyframes highlight {
	from {
		background-color: var(--primary);
	}
	to {
		background-color: transparent;
	}
}

@-webkit-keyframes highlight {
	from {
		background-color: var(--primary);
	}
	to {
		background-color: transparent;
	}
}
@-moz-keyframes highlight {
	from {
		background-color: var(--primary);
	}
	to {
		background-color: transparent;
	}
}

.flash {
	-moz-animation: highlight 0.5s ease 0s 1 alternate;
	-webkit-animation: highlight 0.5s ease 0s 1 alternate;
	animation: highlight 0.5s ease 0s 1 alternate;
}

/*
  # Gradient Backgrounds
  Need to add these because *don't think* tailwind works regularly with gradient backgrounds
*/
.bi-none {
	border-image: none;
}

.bi-app-gradient {
	border-image: var(--app-gradient);
}

.hover\:bi-app-gradient:hover {
	border-image: var(--app-gradient);
}

.background-app-gradient {
	background: var(--app-gradient);
}

.background-app-gradient-half {
	background: linear-gradient(
		90deg,
		rgba(242, 204, 129, 0.5),
		rgba(145, 98, 246, 0.5)
	);
}

.text-gradient-1 {
	background: var(--app-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-gradient-gold {
	background: linear-gradient(
		272.83deg,
		#ffe8c6 0.68%,
		#ffcf52 43.02%,
		#ffe6c2 99.78%
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-gradient-white-shadow {
	background: linear-gradient(180deg, #fff 40%, #424c4f 120%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.text-gradient-black-shadow {
	background: linear-gradient(180deg, #242424 50%, #fff 115%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.background-app-gradient {
	background: var(--app-gradient);
}

/* Tailwind isn't playing nice with stacked backgrounds, define it manually here instead */
.highlightBg {
	background: linear-gradient(
			114.67deg,
			rgba(255, 255, 255, 0.2) 16.59%,
			rgba(0, 0, 0, 0) 56.74%
		),
		linear-gradient(137.87deg, #f6f063 5.36%, rgba(224, 119, 116, 0) 62.77%),
		linear-gradient(83.36deg, #ff3873 3.72%, #711eff 46.75%, #3fe5ff 94.51%);
}

/* Z Indexes */
.z-10 {
	z-index: 10;
}
.z-20 {
	z-index: 20;
}
.z-30 {
	z-index: 30;
}
.z-40 {
	z-index: 40;
}
.z-50 {
	z-index: 50;
}
.z-100 {
	z-index: 100;
}
details {
	position: relative;
	cursor: pointer;
}
details > summary:before {
	height: 8px;
	width: 8px;
	content: ' ';
	display: block;
	position: absolute;
	transform: rotate(-45deg);
	border-bottom: 2px solid #222f40;
	border-right: 2px solid #222f40;
	left: 0px;
	top: 3px;
}
details[open] > summary:before {
	height: 8px;
	width: 8px;
	content: ' ';
	display: block;
	position: absolute;
	border-bottom: 2px solid #222f40;
	border-right: 2px solid #222f40;
	transform: rotate(45deg);
	left: 0px;
	top: 3px;
}
.marginInfoItem {
	padding-left: 15px;
}
.marginInfoItem ul {
	margin-left: -15px;
}
.marginInfoItem ul li:first-child:before {
	height: 10px;
	top: 5px;
}
.marginInfoItem ul li:before {
	content: ' ';
	width: 15px;
	height: 26px;
	border-bottom: 1px solid #1e2f42;
	border-left: 1px solid #1e2f42;
	display: block;
	position: absolute;
	top: -16px;
	left: -20px;
}

.text-shadow {
	text-shadow: 0 2px 1px rgba(0, 0, 0, 0.5);
}

.drop-shadow {
	filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1))
		drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
}

.drop-shadow-thick {
	filter: drop-shadow(0 1px 2px rgb(0 0 0 / 0.5))
		drop-shadow(0 1px 1px rgb(0 0 0 / 0.3));
}

/* Animated Gradient Border Styles */

.gradient-border {
	--borderWidth: 2px;
	background: #0e1928;
	position: relative;
	border-radius: var(--borderWidth);
}

.gradient-border:after {
	content: '';
	position: absolute;
	top: calc(-1 * var(--borderWidth));
	left: calc(-1 * var(--borderWidth));
	height: calc(100% + var(--borderWidth) * 2);
	width: calc(100% + var(--borderWidth) * 2);
	background: linear-gradient(
		60deg,
		#f79533,
		#f37055,
		#ef4e7b,
		#a166ab,
		#5073b8,
		#1098ad,
		#07b39b,
		#6fba82
	);
	border-radius: calc(2 * var(--borderWidth));
	z-index: -1;
	animation: animatedgradient 10s alternate infinite;
	background-size: 300% 300%;
}

@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.pnl-bg {
	background: radial-gradient(
		50% 50% at 50% 50%,
		rgba(4, 9, 17, 0) 0%,
		rgba(4, 9, 17, 0.85) 100%
	);
}

.pnl-matrix-transform {
	transform: matrix(-0.97, -0.22, -0.22, 0.97, 0, 0);
}
.skeleton-animate-dark-theme,
.skeleton-animate-light-theme {
	position: relative;
	overflow: hidden;
	border-radius: 4px;
}

@keyframes shimmer {
	100% {
		transform: translateX(100%);
	}
}

.skeleton-animate-dark-theme::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background: var(--darkBlue-60);
	background-image: linear-gradient(
		75deg,
		rgba(var(--darkBlue-60), 0) 0,
		rgba(var(--darkBlue-60), 0.2) 20%,
		rgba(var(--darkBlue-60), 0.5) 60%,
		rgba(var(--darkBlue-60), 0)
	);
	border-radius: 4px;
	animation: shimmer 1.3s infinite;
	content: '';
}

.skeleton-animate-light-theme::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transform: translateX(-100%);
	background: var(--container-border);
	background-image: linear-gradient(
		75deg,
		rgba(var(--container-border), 0) 0,
		rgba(var(--container-border), 0.2) 20%,
		rgba(var(--container-border), 0.5) 60%,
		rgba(var(--container-border), 0)
	);
	border-radius: 4px;
	animation: shimmer 1.3s infinite;
	content: '';
}

.skeleton-container-dark-theme {
	display: block;
	border-radius: 4px;
	border: 1px solid var(--bkg-0);
	padding: 1px;
	background-color: var(--darkBlue-90);
}

.skeleton-container-light-theme {
	display: block;
	border-radius: 4px;
	border: 1px solid var(--bkg-0);
	padding: 1px;
	background-color: var(--th-main-bg-light);
}

.octagon-bg {
	background-image: radial-gradient(rgba(145, 98, 246, 0.4), black),
		url('../../public/assets/octagon-bg.jpeg');
	background-repeat: no-repeat;
	background-position: top;
}

.dark-theme .cardHighlightBg {
	background: linear-gradient(
		90deg,
		rgba(19, 34, 54, 0) 0%,
		rgba(39, 28, 88, 0.8) 10.47%,
		#0adde9 49.62%,
		rgba(39, 28, 88, 0.8) 89.45%,
		rgba(19, 34, 54, 0) 99.25%
	);
}

.light-theme .cardHighlightBg {
	background: linear-gradient(
		90deg,
		rgba(207, 221, 239, 0) 0%,
		rgba(95, 165, 255, 0.8) 21.71%,
		#3adcff 49.62%,
		rgba(95, 165, 255, 0.8) 78.57%,
		rgba(207, 221, 239, 0) 99.25%
	);
}

.vaultIconHighlight {
	filter: drop-shadow(0px 4px 20px rgba(54, 78, 201, 0.2));
}

.dark-theme .faqGradientBg {
	background: radial-gradient(
		circle at top,
		rgba(25, 29, 44, 0.5) 0%,
		rgba(25, 29, 44, 0) 60%
	);
}

.light-theme .faqGradientBg {
	background: radial-gradient(
		circle at top,
		rgba(222, 235, 248, 0.3) 0%,
		rgba(255, 255, 255, 0) 60%
	);
}

.highlightButtonBg {
	background: linear-gradient(
			114.67deg,
			rgba(255, 255, 255, 0.2) 16.59%,
			rgba(0, 0, 0, 0) 56.74%
		),
		conic-gradient(
			from 152.49deg at 17.16% 25.88%,
			rgba(255, 255, 255, 0) -21.19deg,
			#ffffff 31.11deg,
			rgba(255, 255, 255, 0) 338.81deg,
			#ffffff 391.11deg
		),
		conic-gradient(
			from 149.39deg at 2.64% 35.15%,
			rgba(255, 255, 255, 0) 0deg,
			#ffffff 333.31deg,
			rgba(255, 255, 255, 0) 360deg
		),
		linear-gradient(137.87deg, #f6f063 5.36%, rgba(224, 119, 116, 0) 62.77%),
		linear-gradient(83.36deg, #ff3873 3.72%, #711eff 46.75%, #3fe5ff 94.51%);
}

.standardButtonBg:hover {
	filter: brightness(110%);
}

.standardButtonBg:active:hover {
	filter: brightness(90%);
}

/* Animated favoriting */

.star {
	cursor: pointer;
	height: 14px;
	width: 14px;
	background-image: url('../../public/assets/favoriteSprite.webp');
	background-position: left;
	background-repeat: no-repeat;
	background-size: 2300%;
}

.star-faved {
	background-position: right;
}

.is_animating {
	animation: star-burst 0.8s steps(21) 1;
}

@keyframes star-burst {
	from {
		background-position: left;
	}
	to {
		background-position: right;
	}
}

.dark-theme .toast-error-bg {
	background: linear-gradient(
			90deg,
			rgba(255, 136, 127, 0.15) 0%,
			rgba(255, 136, 127, 0) 100%
		),
		#080f18;
}

.dark-theme .toast-success-bg {
	background: linear-gradient(
			90deg,
			rgba(52, 203, 136, 0.15) 0%,
			rgba(52, 203, 136, 0) 100%
		),
		#080f18;
}

.light-theme .toast-error-bg {
	background: linear-gradient(
			90deg,
			rgba(255, 136, 127, 0.25) 0%,
			rgba(255, 136, 127, 0) 100%
		),
		#ffffff;
}

.light-theme .toast-success-bg {
	background: linear-gradient(
			90deg,
			rgba(52, 203, 136, 0.25) 0%,
			rgba(52, 203, 136, 0) 100%
		),
		#ffffff;
}

/* Evan Todo: Try to turn this custom arrow into a tippy style override instead of an entire new dom element */
.rainbow-gradient-arrow-up {
	position: absolute;
	padding: 0;
	margin: 0;
	transform: rotate(45deg);
	width: 12px;
	height: 12px;
	top: -4px;
}
.rainbow-gradient-arrow-up.line {
	top: -6px;
	background: #9468f1;
}

.rainbow-gradient-border {
	position: relative;
}

.rainbow-gradient-border::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 4px;
	/* controls border thickness */
	padding: 2px;
	background: var(--app-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.rainbow-gradient-border-rounded {
	position: relative;
}

.rainbow-gradient-border-rounded::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 50%;
	/* controls border thickness */
	padding: 1.5px;
	background: var(--app-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.rainbow-gradient-border-2 {
	border: 1px solid;

	border-image-source: var(--app-gradient);
	border-image-slice: 1;
}

.fuel-gradient-border {
	position: relative;
}

.fuel-gradient-border::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 8px;
	/* controls border thickness */
	padding: 1px;
	background: var(--fuel-gradient);
	background-size: 160% 160%;
	background-position-y: 15%;
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.prize-gradient-border {
	position: relative;
}

.prize-gradient-border::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 4px;
	/* controls border thickness */
	padding: 2px;
	background: var(--prize-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.prize-gradient-border-rounded {
	position: relative;
}

.prize-gradient-border-rounded::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 50%;
	/* controls border thickness */
	padding: 1.5px;
	background: var(--prize-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.market-bar-rainbow-gradient {
	position: relative;
}

.market-bar-rainbow-gradient::before {
	content: '';
	position: absolute;
	inset: 2px 0;
	/* border-radius: 4px; */
	/* controls border thickness */
	padding: 1.5px;
	background: var(--app-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.market-bar-rainbow-gradient::before:nth-child(1) {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.swift-gradient-border {
	position: relative;
}

.swift-gradient-border::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 4px;
	/* controls border thickness */
	padding: 1px;
	background: var(--swift-gradient);
	background-size: 160% 160%;
	background-position-y: 15%;
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.text-swift-gradient {
	background: var(--swift-gradient);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.onboarding-steps-flipper {
	perspective: 500px;
}

.onboarding-steps-flipper .onboarding-steps-flipper-inside {
	transition: transform 0.5s;
	transform-style: preserve-3d;
}

.onboarding-steps-flipper .onboarding-steps-flipper-inside.flipped {
	transform: rotateY(180deg);
}

.onboarding-steps-flipper .onboarding-steps-flipper-inside > div {
	backface-visibility: hidden;
}

.onboarding-steps-flipper .flipper-back {
	top: -4px;
	position: absolute;
	transform: rotateY(180deg);
}

.onboarding-steps-mask {
	mask-image: url(/assets/images/onboarding-steps-mask.svg);
	mask-repeat: no-repeat;
	mask-position: center;
	mask-size: contain;
}

/* Fixes weird extra few px of scrolling at bottom of the page */
#headlessui-portal-root {
	position: absolute;
	overflow: hidden;
}

.warn-yellow-bg-translucent {
	background-color: #f2c94c20;
}

.apr-gradient-text {
	background-clip: text !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
	color: transparent;
	display: inline-block;
	background: linear-gradient(
		90deg,
		#85e0b8 0%,
		#34cb88 40%,
		#a5e3c8 50%,
		#85e0b8 75%,
		#85e0b8 100%
	);
	background-size: 250% 250%;
	background-position: 0% 0%;
	transition: background-position 0.5s ease-out;
}

.light-theme .apr-gradient-text {
	background: linear-gradient(
		90deg,
		#29b577 0%,
		#209d66 40%,
		#85e0b8 50%,
		#29b577 75%,
		#29b577 100%
	);
	background-size: 250% 250%;
	background-position: 0% 0%;
}

.apr-hover-zone:hover .apr-gradient-text {
	background-position: 120% 0%;
}

.prize-gradient-text {
	background-clip: text !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
	color: transparent;
	display: inline-block;
	background: linear-gradient(90deg, #ffdead 0%, #ffcf52 70%, #fca239 90%);
	background-size: 100%;
	background-position: 0%;
	transition: background-position 0.5s ease-out;
}

.fuel-gradient-text {
	background: radial-gradient(
		circle at center,
		#0ab8df 0%,
		#d867ea 75%,
		#fded5d 87%,
		#00295f 100%
	);
	background-size: 100%;
	background-position: 0%;
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

/* Overriding some wormhole connect stuff that I couldn't do with their theme config: */
#wormhole-connect > div > div > div {
	margin: 0 !important;
}
.MuiModal-backdrop {
	backdrop-filter: blur(5px) !important;
}
.light-theme .MuiModal-backdrop {
	background-color: rgba(255, 255, 255, 0.5) !important;
}

/* Override for orderbook icons when not selected */
/* Because multiple colors are baked into the child elements of the SVG, there's no way to set the color with tailwind */
.icon-label rect {
	fill: var(--text-tertiary);
}

.solHighlightBg {
	background: linear-gradient(
		90deg,
		transparent 0%,
		transparent 10.47%,
		#7e26bc 49.62%,
		transparent 89.45%,
		transparent 99.25%
	);
}

.btcHighlightBg {
	background: linear-gradient(
		90deg,
		transparent 0%,
		transparent 10.47%,
		#f7931a 49.62%,
		transparent 89.45%,
		transparent 99.25%
	);
}

.ethHighlightBg {
	background: linear-gradient(
		90deg,
		transparent 0%,
		transparent 10.47%,
		#7a57dd 49.62%,
		transparent 89.45%,
		transparent 99.25%
	);
}

.freeTicketsRadialGlow {
	width: 180.394px;
	height: 532.18px;
	transform: rotate(-45deg);
	flex-shrink: 0;
	background: radial-gradient(
		50.51% 50.51% at 50% 50%,
		rgba(63, 229, 255, 0.8) 0%,
		rgba(1, 8, 10, 0.8) 100%
	);
	filter: blur(100px);
}

.freeTicketsBackgroundShade {
	position: absolute;
	inset: 0;
	background: linear-gradient(
		45deg,
		rgba(4, 9, 17, 0.7) 93.84%,
		rgba(4, 9, 17, 0) 50.94%,
		rgba(0, 0, 0, 0.7) 93.84%
	);
}

.winnerTextGlow {
	color: #5b2813;
	font-family: Days One;
	font-size: 32px;
	text-shadow: 0px 0px 4px #ffbd70;
	-webkit-text-stroke-width: 1px;
	-webkit-text-stroke-color: #ffbd70;
}

.brand-box-glow {
	box-shadow: 0px 0px 10px 0px rgba(232, 162, 160, 0.75);
}

.prize-box-glow {
	box-shadow: 0px 0px 10px 0px rgba(255, 140, 0, 0.75),
		0px 0px 30px 0px rgba(255, 140, 0, 0.29) inset;
}

.recharts-cartesian-axis-tick > text {
	fill: var(--text-label);
	font-weight: 300;
	font-size: 13px;
}

#market-funding-history .recharts-cartesian-axis-tick > text {
	fill: var(--text-label);
	font-weight: 300;
	font-size: 11px;
}

#market-funding-history .recharts-label {
	fill: var(--text-label);
	font-weight: 300;
	opacity: 0.65;
	font-size: 14px;
}

.animate-active-dot {
	transition: transform 0.3s ease-in-out;
	transform-origin: center center;
	transform: scale(0.3);
}

.drift-points-text-gradient {
	background: var(--drift-points-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

/** Custom border CSS to allow for transparent background */
.drift-points-border {
	display: flow-root;
	position: relative;
}

.drift-points-border::before {
	content: '';
	position: absolute;
	inset: 0;
	border-radius: 4px;
	padding: 2px;
	background: var(--drift-points-gradient);
	mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	mask-composite: xor;
	mask-composite: exclude;
	pointer-events: none;
}

.points-toast-bg-light {
	background: linear-gradient(
			90deg,
			rgba(32, 121, 255, 0.06) 0%,
			rgba(243, 47, 163, 0.11) 100%
		),
		#fff;
}

.points-toast-bg-dark {
	background: linear-gradient(
			90deg,
			rgba(33, 24, 119, 0.2) 11.49%,
			rgba(244, 3, 128, 0.08) 126.92%
		),
		#080f18;
}

.league-gold-text-gradient {
	background: var(--drift-league-gold);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

.league-silver-text-gradient {
	background: var(--drift-league-silver);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

.league-bronze-text-gradient {
	background: var(--drift-league-bronze);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

.mobile-chart-height {
	height: 350px;
	height: calc(100vh - 280px);
	min-height: 350px;
}

.drift-earn-text-gradient {
	background: var(--drift-earn-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
	color: transparent;
}

.drift-gradient-text {
	background: var(--app-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.vaults-gradient-text {
	background: var(--vaults-bg-gradient);
	-webkit-background-clip: text;
	background-clip: text;
	-webkit-text-fill-color: transparent;
}

.dialect {
	--dt-accent-brand: var(--interactive-link) !important;
	--dt-accent-error: var(--negative-red) !important;
	--dt-bg-primary: var(--container-bg) !important;
	--dt-input-checked: var(--interactive-link) !important;
	--dt-text-accent: var(--interactive-link) !important;
	--dt-bg-secondary: var(--input-border) !important;
	/* --dt-bg-tertiary: var(--input-border) !important; */
	/* --dt-input-primary: var(--input-bg) !important; */
	/* --dt-input-secondary: var(--input-bg-hover) !important; */
	/* --dt-input-unchecked: var(--input-bg-hover) !important; */
	--dt-button-primary: var(--interactive-link) !important;
}

.dialect[data-theme='dark'] {
	--dt-accent-brand: var(--interactive-link) !important;
	--dt-accent-error: var(--negative-red) !important;
	--dt-bg-primary: var(--container-bg) !important;
	--dt-input-checked: var(--interactive-link) !important;
	--dt-text-accent: var(--interactive-link) !important;
	--dt-bg-secondary: var(--container-bg-hover) !important;
	--dt-bg-tertiary: var(--input-border) !important;
	--dt-input-primary: var(--input-bg) !important;
	--dt-input-secondary: var(--input-bg-hover) !important;
	/* --dt-input-unchecked: var(--input-bg-hover) !important; */
	--dt-button-primary: var(--interactive-link) !important;

	/* --dt-button-secondary: var(--button-secondary-bg) !important; */

	/* --dt-bg-brand: #ebebeb !important;
	--dt-brand-transparent: #b3b3b31a !important;
	--dt-button-primary-disabled: #656564 !important;
	--dt-button-primary-hover: #434445 !important;
	--dt-button-secondary-disabled: #f2f3f5 !important;
	--dt-button-secondary-hover: #f2f3f5 !important;
	--dt-icon-primary: #2a2a2b !important;
	--dt-icon-secondary: #888989 !important;
	--dt-icon-tertiary: #b3b3b3 !important;
	--dt-stroke-primary: #dee1e7 !important;
	--dt-text-inverse: #ffffff !important;
	--dt-text-primary: #232324 !important;
	--dt-text-quaternary: #888989 !important;
	--dt-text-secondary: #434445 !important;
	--dt-text-tertiary: #737373 !important; */
}

#dialect-notifications-button {
	background-color: var(--button-secondary-bg);
}

#dialect-notifications-button:hover {
	background-color: var(--button-secondary-bg-hover);
}
