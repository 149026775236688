/* Carousel */

.carousel-base {
	width: 100%;
	box-sizing: border-box;
	display: flex;
	outline: none;
	position: relative;
}

.item-provider {
	width: 100%;
	position: relative;
	overflow: visible;
}

.item-container img {
	user-select: none;
	-webkit-user-drag: none;
}

.item-tracker {
	height: 100%;
	display: flex;
}

.carousel-arrow-bg-right {
	background: linear-gradient(to right, transparent, transparent);
	position: absolute;
	right: 0;
	top: 0;
}

.carousel-arrow-bg-left {
	background: linear-gradient(to left, transparent, transparent);
	position: absolute;
}

.carousel-navigation {
	display: flex;
	position: absolute;
	bottom: 0;
}

.sliderBase {
	width: 100%;
	position: relative;
}

.slider {
	display: flex;
	overflow-x: auto;
	scrollbar-width: none; /* Firefox 64 */
	-ms-overflow-style: none; /* Internet Explorer 11 */
}

.slider::-webkit-scrollbar {
	/** WebKit */
	display: none;
}

.slider > * {
	flex: 0 0 auto;
}

.sliding > * {
	pointer-events: none;
}
