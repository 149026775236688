/* Override Mayan widget styles */
#swap_widget {
	border: solid 2px var(--container-border);
	border-radius: 8px;
	flex-shrink: 1;
	max-width: 380px;
	min-width: 300px;
	width: 100%;

	.MuiBox-root {
		border-color: var(--container-border);
		border-radius: 8px;
	}

	.MuiTypography-root {
		font-family: Marfa;
		color: var(--text-default);
	}

	.MuiButton-root {
		font-family: Marfa;
		white-space: nowrap;
	}

	.MuiInputBase-input {
		padding: 4px 0px 0px;
	}

	.MuiOutlinedInput-input {
		padding: 16px 24px;
	}

	.MuiOutlinedInput-root,
	.MuiOutlinedInput-root.Mui-focused {
		border: 1px solid var(--container-border) !important;
	}

	.MuiTypography-body3 {
		color: var(--text-label);
	}

	.MuiCheckbox-root {
		color: var(--container-border) !important;
	}

	.MuiButton-textPrimary {
		color: var(--text-default) !important;
	}

	#MAYAN_SWAP_PV_ROOT {
		width: 100%;
		min-height: 520px;
		max-height: 560px;

		/* From-token input*/
		> div:nth-child(2) {
			background-color: var(--container-bg);

			.MuiInputBase-input {
				font-family: Marfa;
				font-size: 21px;
			}

			.MuiButton-root:nth-child(2) > span {
				font-size: 13px;
				color: var(--text-default);
			}
		}

		/* To-token input*/
		> div:nth-child(4) {
			background-color: var(--container-bg);

			.MuiTypography-body0 {
				font-size: 21px;
			}
			.MuiTypography-body4 {
				padding-bottom: 4px;
			}

			.MuiButton-root:nth-child(2) > span {
				font-size: 13px;
				color: var(--text-default);
			}

			> div:nth-child(3) {
				background-color: var(--container-bg-hover);
			}
		}

		> div:nth-child(6) > button {
			white-space: normal;
			height: auto;
		}

		/* Powered by Mayan text*/
		> div:last-child > a > span {
			color: var(--text-secondary);
		}
	}

	/* Secondary pages */
	.MuiPaper-root {
		background-color: var(--main-bg);
		padding: 12px;
	}

	/* Recent swaps container */
	.MuiDialogContent-root > .MuiBox-root:last-child {
		> div {
			gap: 8px;
			margin-bottom: 4px;

			.MuiTypography-root {
				line-height: 20px;
			}
		}

		/* Explorer button in success page*/
		> a {
			margin-top: 24px;
		}
	}
}

.MuiPopover-root {
	.MuiTypography-root {
		font-family: Marfa;
		color: var(--text-default);
	}

	.MuiButton-root:hover {
		background-color: var(--container-bg-hover) !important;
	}
}

.light-theme #swap_widget {
	.MuiButton-root,
	#MAYAN_SWAP_PV_ROOT > div > div > button {
		border: solid 1px var(--container-border);
	}

	.MuiButton-textSizeSmall {
		border: none;
	}

	.MuiButton-root:hover {
		background-color: var(--container-bg-hover) !important;
	}

	#MAYAN_SWAP_PV_ROOT {
		border-radius: 6px;
	}

	.MuiButton-outlined {
		color: var(--text-disabled);
	}
}

.dark-theme #swap_widget {
	#MAYAN_SWAP_PV_ROOT > div:first-child > div > p,
	.MuiPaper-root > div:first-child > span {
		color: var(--text-emphasis);
	}
}
