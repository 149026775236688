/********************************************/
/* Figma Variables - Generated from Figma   */
/********************************************/

.light-theme {
	/* background */
	--main-bg: theme('colors.neutrals.0'); /*                         #ffffff */
	--container-bg: theme('colors.neutrals.0'); /*                    #ffffff */
	--container-bg-hover: theme('colors.lightBlue.0'); /*             #f3f9fe */
	--container-bg-selected: theme('colors.lightBlue.0'); /*          #f3f9fe */
	--container-bg-tweet: theme('colors.lightBlue.0'); /*             #f3f9fe */
	--container-border: theme('colors.lightBlue.10'); /*              #e4edf7 */
	--tooltip-bg: theme('colors.lightBlue.0'); /*                     #f3f9fe */
	--tab-selected-bg: theme('colors.lightBlue.10'); /*               #e4edf7 */
	--card-border-hover: theme('colors.darkBlue.0'); /*               #dae8fa */
	--fuel-table-header-bg: theme('colors.darkBlue.110'); /*          #030a13 */
	--fuel-table-row-bg: theme('colors.darkBlue.100'); /*             #080f18 */

	/* button-tab */
	--button-border: theme('colors.lightBlue.20'); /*                 #cfddef */
	--button-disabled: theme('colors.lightBlue.10'); /*               #e4edf7 */
	--border-2: theme('colors.lightBlue.10'); /*                      #e4edf7 */
	--button-secondary-bg: theme('colors.lightBlue.20'); /*           #cfddef */
	--button-secondary-bg-hover: theme('colors.darkBlue.0'); /*       #dae8fa */
	--button-secondary-stroke-hover: theme('colors.lightBlue.10'); /* #e4edf7 */
	--button-secondary-selected: theme('colors.darkBlue.0'); /*       #dae8fa */
	--tabs-selected: theme('colors.lightBlue.10'); /*                 #e4edf7 */
	--tabs-hover: theme('colors.lightBlue.10'); /*                    #e4edf7 */
	--tabs-default-fill: theme('colors.neutrals.0'); /*               #ffffff */
	--chip-default-bg: theme('colors.darkBlue.0'); /*                 #dae8fa */
	--chip-default-text: theme('colors.neutrals.90'); /*              #121217 */
	--connect-button-icon-background: theme('colors.neutrals.0'); /*  #ffffff */
	--connect-button-icon-border: theme('colors.neutrals.0'); /*      #ffffff */
	--status-neutral-bg: theme('colors.neutrals.50'); /*              #555562 */
	--status-neutral-text: theme('colors.lightBlue.10'); /*           #e4edf7 */
	--feedback-button: theme('colors.neutrals.50'); /*                #555562 */
	--button-border-hover: rgba(255, 255, 255, 0);

	/* input */
	--input-bg: theme('colors.lightBlue.10'); /*                      #e4edf7 */
	--input-bg-hover: theme('colors.lightBlue.20'); /*                #cfddef */
	--input-border: theme('colors.darkBlue.0'); /*                    #dae8fa */

	/* divider-scroll */
	--stroke-secondary: theme('colors.lightBlue.10'); /*              #e4edf7 */
	--scroll-bar: theme('colors.lightBlue.0'); /*                     #f3f9fe */

	/* text */
	--text-default: theme('colors.neutrals.90'); /*                   #121217 */
	--text-selected: theme('colors.neutrals.0'); /*                   #ffffff */
	--text-inverted-selected: theme('colors.neutrals.110'); /*        #000000 */
	--text-secondary: theme('colors.neutrals.50'); /*                 #555562 */
	--text-tertiary: theme('colors.neutrals.40'); /*                  #787883 */
	--text-disabled: theme('colors.neutrals.30'); /*                  #a1a1aa */
	--text-emphasis: theme('colors.neutrals.90'); /*                  #121217 */
	--text-interactive: theme('colors.neutrals.50'); /*               #555562 */
	--interactive-link: theme('colors.purple.50'); /*                 #9162f6 */
	--text-secondary-button: theme('colors.neutrals.90'); /*          #121217 */
	--text-featured-chip: theme('colors.neutrals.0'); /*              #ffffff */
	--text-gradient-button: theme('colors.neutrals.110'); /*          #000000 */
	--text-negative-red-button: theme('colors.red.60'); /*            #e54d48 */
	--text-positive-green-button: theme('colors.green.60'); /*        #29b577 */
	--text-label: theme('colors.neutrals.50'); /*                     #555562 */
	--text-input: theme('colors.neutrals.90'); /*                     #121217 */
	--text-default-2: theme('colors.neutrals.90'); /*                 #121217 */
	--text-primary-button: theme('colors.neutrals.110'); /*           #000000 */
	--static-emphasized: theme('colors.neutrals.90'); /*              #121217 */
	--static-default: theme('colors.neutrals.50'); /*                 #555562 */

	/* color */
	--positive-green: theme('colors.green.50'); /*                    #34cb88 */
	--positive-green-hover: theme('colors.green.60'); /*              #29b577 */
	--positive-green-pressed: theme('colors.green.60'); /*            #29b577 */
	--negative-red: theme('colors.red.50'); /*                        #ff615c */
	--negative-red-hover: theme('colors.red.60'); /*                  #e54d48 */
	--negative-red-pressed: theme('colors.red.60'); /*                #e54d48 */
	--brand-yellow: theme('colors.yellow.50'); /*                     #f2c94c */
	--brand-yellow-hover: theme('colors.yellow.70'); /*               #b99b3f */
	--brand-yellow-pressed: theme('colors.yellow.70'); /*             #b99b3f */
	--warn-yellow: theme('colors.yellow.60'); /*                      #e3bb42 */
	--warn-yellow-hover: theme('colors.yellow.70'); /*                #b99b3f */
	--warn-yellow-pressed: theme('colors.yellow.70'); /*              #b99b3f */
	--label-secondary-bg: theme('colors.neutrals.50'); /*             #555562 */
	--buy-hover: theme('colors.green.60'); /*                         #29b577 */
	--buy-pressed: theme('colors.green.60'); /*                       #29b577 */
	--sell-hover: theme('colors.red.60'); /*                          #e54d48 */
	--sell-pressed: theme('colors.red.60'); /*                        #e54d48 */
	--white: theme('colors.neutrals.0'); /*                           #ffffff */
	--positive-green-secondary-bg: rgba(52, 203, 136, 0.2);
	--negative-red-secondary-bg: rgba(255, 97, 92, 0.2);
	--warn-yellow-secondary-bg: rgba(242, 201, 76, 0.2);
	--brand-yellow-secondary-bg: rgba(242, 201, 76, 0.15);
	--interactive-secondary-bg: rgba(145, 98, 246, 0.12);

	/* grad */
	--grad_brand_2: theme('colors.pink.60'); /*                       #ff3873 */
	--grad_brand_3: theme('colors.purple.60'); /*                     #711eff */
	--grad_primary_1: #e8a2a0;
	--grad_primary_2: #9468f1;
	--grad_primary_3: #71cce9;
	--grad_brand_1: #f6f063;
	--grad_brand_4: #3fe5ff;
	--grad_brand_5: rgba(224, 119, 116, 0);
	--white_20: rgba(255, 255, 255, 0.2);
	--white_10: rgba(255, 255, 255, 0.1);
	--white_0: rgba(255, 255, 255, 0);
	--black_0: rgba(0, 0, 0, 0);
	--grad_point_1: #ff2897;
	--grad_point_2: #8e67ff;
	--grad_point_3: #007bff;
	--grad_point_4: #8bc3ff;
	--grad_fuel_1: #0ab8df;
	--grad_fuel_2: #d867ea;
	--grad_fuel_3: #fded5d;
	--grad_fuel_4: #00295f;
	--grad_fuel_5: #132236;
	--grad_fuel_6: #f3abff;
	--grad_fuel_7: #69ebff;
	--grad_fuel_8: #132236;
}

.dark-theme {
	/* background */
	--main-bg: theme('colors.darkBlue.110'); /*                       #030a13 */
	--container-bg: theme('colors.darkBlue.100'); /*                  #080f18 */
	--container-bg-hover: theme('colors.darkBlue.70'); /*             #111d2e */
	--container-bg-selected: theme('colors.darkBlue.70'); /*          #111d2e */
	--container-bg-tweet: theme('colors.darkBlue.70'); /*             #111d2e */
	--container-border: theme('colors.darkBlue.80'); /*               #101a27 */
	--tooltip-bg: theme('colors.darkBlue.50'); /*                     #152a44 */
	--tab-selected-bg: theme('colors.darkBlue.80'); /*                #101a27 */
	--card-border-hover: theme('colors.darkBlue.50'); /*              #152a44 */
	--fuel-table-header-bg: theme('colors.darkBlue.110'); /*          #030a13 */
	--fuel-table-row-bg: theme('colors.darkBlue.100'); /*             #080f18 */

	/* button-tab */
	--button-border: theme('colors.darkBlue.60'); /*                  #132236 */
	--button-disabled: theme('colors.darkBlue.80'); /*                #101a27 */
	--border-2: theme('colors.darkBlue.80'); /*                       #101a27 */
	--button-secondary-bg: theme('colors.darkBlue.60'); /*            #132236 */
	--button-secondary-bg-hover: theme('colors.darkBlue.50'); /*      #152a44 */
	--button-secondary-stroke-hover: theme('colors.darkBlue.80'); /*  #101a27 */
	--button-secondary-selected: theme('colors.darkBlue.50'); /*      #152a44 */
	--tabs-selected: theme('colors.darkBlue.80'); /*                  #101a27 */
	--tabs-hover: theme('colors.darkBlue.80'); /*                     #101a27 */
	--tabs-default-fill: theme('colors.darkBlue.100'); /*             #080f18 */
	--chip-default-bg: theme('colors.darkBlue.50'); /*                #152a44 */
	--chip-default-text: theme('colors.neutrals.20'); /*              #d4d4d8 */
	--connect-button-icon-background: theme('colors.darkBlue.110'); /*#030a13 */
	--connect-button-icon-border: theme('colors.darkBlue.110'); /*    #030a13 */
	--status-neutral-bg: theme('colors.darkBlue.20'); /*              #6683a7 */
	--status-neutral-text: theme('colors.darkBlue.80'); /*            #101a27 */
	--feedback-button: theme('colors.darkBlue.20'); /*                #6683a7 */
	--button-border-hover: rgba(255, 255, 255, 0);

	/* input */
	--input-bg: theme('colors.darkBlue.80'); /*                       #101a27 */
	--input-bg-hover: theme('colors.darkBlue.60'); /*                 #132236 */
	--input-border: theme('colors.darkBlue.50'); /*                   #152a44 */

	/* divider-scroll */
	--stroke-secondary: theme('colors.darkBlue.80'); /*               #101a27 */
	--scroll-bar: theme('colors.darkBlue.70'); /*                     #111d2e */

	/* text */
	--text-default: theme('colors.neutrals.20'); /*                   #d4d4d8 */
	--text-selected: theme('colors.darkBlue.100'); /*                 #080f18 */
	--text-inverted-selected: theme('colors.neutrals.110'); /*        #000000 */
	--text-secondary: theme('colors.darkBlue.20'); /*                 #6683a7 */
	--text-tertiary: theme('colors.darkBlue.30'); /*                  #465e7a */
	--text-disabled: theme('colors.darkBlue.40'); /*                  #2e4665 */
	--text-emphasis: theme('colors.neutrals.20'); /*                  #d4d4d8 */
	--text-interactive: theme('colors.darkBlue.20'); /*               #6683a7 */
	--interactive-link: theme('colors.purple.40'); /*                 #b58aff */
	--text-secondary-button: theme('colors.neutrals.20'); /*          #d4d4d8 */
	--text-featured-chip: theme('colors.neutrals.0'); /*              #ffffff */
	--text-gradient-button: theme('colors.neutrals.110'); /*          #000000 */
	--text-negative-red-button: theme('colors.red.40'); /*            #ff887f */
	--text-positive-green-button: theme('colors.green.40'); /*        #5dd5a0 */
	--text-label: theme('colors.darkBlue.20'); /*                     #6683a7 */
	--text-input: theme('colors.neutrals.20'); /*                     #d4d4d8 */
	--text-default-2: theme('colors.neutrals.20'); /*                 #d4d4d8 */
	--text-primary-button: theme('colors.neutrals.110'); /*           #000000 */
	--static-emphasized: theme('colors.neutrals.20'); /*              #d4d4d8 */
	--static-default: theme('colors.darkBlue.20'); /*                 #6683a7 */

	/* color */
	--positive-green: theme('colors.green.50'); /*                    #34cb88 */
	--positive-green-hover: theme('colors.green.40'); /*              #5dd5a0 */
	--positive-green-pressed: theme('colors.green.40'); /*            #5dd5a0 */
	--negative-red: theme('colors.red.50'); /*                        #ff615c */
	--negative-red-hover: theme('colors.red.40'); /*                  #ff887f */
	--negative-red-pressed: theme('colors.red.40'); /*                #ff887f */
	--brand-yellow: theme('colors.yellow.50'); /*                     #f2c94c */
	--brand-yellow-hover: theme('colors.yellow.40'); /*               #ffdd75 */
	--brand-yellow-pressed: theme('colors.yellow.40'); /*             #ffdd75 */
	--warn-yellow: theme('colors.yellow.50'); /*                      #f2c94c */
	--warn-yellow-hover: theme('colors.yellow.40'); /*                #ffdd75 */
	--warn-yellow-pressed: theme('colors.yellow.40'); /*              #ffdd75 */
	--label-secondary-bg: theme('colors.darkBlue.20'); /*             #6683a7 */
	--buy-hover: theme('colors.green.40'); /*                         #5dd5a0 */
	--buy-pressed: theme('colors.green.40'); /*                       #5dd5a0 */
	--sell-hover: theme('colors.red.40'); /*                          #ff887f */
	--sell-pressed: theme('colors.red.40'); /*                        #ff887f */
	--white: theme('colors.neutrals.0'); /*                           #ffffff */
	--positive-green-secondary-bg: rgba(52, 203, 136, 0.2);
	--negative-red-secondary-bg: rgba(255, 97, 92, 0.2);
	--warn-yellow-secondary-bg: rgba(242, 201, 76, 0.2);
	--brand-yellow-secondary-bg: rgba(242, 201, 76, 0.2);
	--interactive-secondary-bg: rgba(181, 138, 255, 0.12);

	/* grad */
	--grad_brand_2: theme('colors.pink.60'); /*                       #ff3873 */
	--grad_brand_3: theme('colors.purple.60'); /*                     #711eff */
	--grad_primary_1: #e8a2a0;
	--grad_primary_2: #9468f1;
	--grad_primary_3: #71cce9;
	--grad_brand_1: #f6f063;
	--grad_brand_4: #3fe5ff;
	--grad_brand_5: rgba(224, 119, 116, 0);
	--white_20: rgba(255, 255, 255, 0.2);
	--white_10: rgba(255, 255, 255, 0.1);
	--white_0: rgba(255, 255, 255, 0);
	--black_0: rgba(0, 0, 0, 0);
	--grad_point_1: #ff2897;
	--grad_point_2: #8e67ff;
	--grad_point_3: #007bff;
	--grad_point_4: #8bc3ff;
	--grad_fuel_1: #0ab8df;
	--grad_fuel_2: #d867ea;
	--grad_fuel_3: #fded5d;
	--grad_fuel_4: #00295f;
	--grad_fuel_5: #132236;
	--grad_fuel_6: #f3abff;
	--grad_fuel_7: #69ebff;
	--grad_fuel_8: #132236;
}
