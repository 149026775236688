/* Fonts */
.ft-lato {
	font-family: Lato;
}

@font-face {
	font-family: 'Post Grotesk';
	src: url('/assets/fonts/PostGrotesk-Bold.woff') format('woff');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Post Grotesk';
	src: url('/assets/fonts/PostGrotesk-Medium.woff') format('woff');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Post Grotesk';
	src: url('/assets/fonts/PostGrotesk-Book.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa';
	src: url('/assets/fonts/EduMarfaVariable.woff') format('woff-variations');
	font-weight: 1 999;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Thin-Trial.woff2') format('woff2');
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Light-Trial.woff2') format('woff2');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Regular-Trial.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Medium-Trial.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Semibold-Trial.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Bold-Trial.woff2') format('woff2');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Marfa Mono';
	src: url('/assets/fonts/ABCMarfaMono-Black-Trial.woff2') format('woff2');
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Chakra Petch';
	src: url('/assets/fonts/ChakraPetch-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Retronoid';
	src: local('Retronoid'), url('/assets/fonts/Retronoid.ttf') format('truetype');
}