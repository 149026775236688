.grid-container {
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 45px 1fr 20px;
	padding: 10px !important;
	background: #030911;
	grid-template-areas:
		'header'
		'main'
		'footer';
	height: 100vh;
}
.header {
	grid-area: header;
}
.main {
	min-height: 0;
	overflow: auto;
	grid-area: main;
}
.main-overview {
	display: grid;
	/*grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));*/
	grid-template-columns: 1fr 320px;
	grid-auto-rows: calc(100vh);
	grid-column-gap: 10px;
	/*background:#000;*/
}
.topcontainer {
	grid-template-columns: 320px 1fr;
	display: grid;
	grid-gap: 10px;
}

.buyandsellcontaniner {
	grid-template-rows: 1fr 300px;
	display: grid;
	grid-row-gap: 20px;
}
.subsubheader {
	display: grid;
	grid-template-columns: 320px 1fr;
	grid-gap: 10px;
}
.chartinfo {
	display: grid;
	grid-template-rows: 260px 1fr;
	grid-row-gap: 10px;
}
.footer {
	grid-area: footer;
}
@media all and (min-width: 768px) and (max-width: 1024px) {
}
@media all and (min-width: 240px) and (max-width: 768px) {
	.main-overview {
		display: block;
		overflow-x: hidden;
		width: 100%;
	}
	.grid-container {
		padding: 0 !important;
	}
}
