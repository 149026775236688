@layer utilities {
	/* Headings */
	.typo-h1 {
		font-size: 124px;
		line-height: 152px;
		font-weight: var(--heading-font-weight);
	}
	.typo-h2 {
		font-size: 80px;
		line-height: 80px;
		font-weight: var(--heading-font-weight);
	}
	.typo-h3 {
		font-size: 64px;
		line-height: 80px;
		font-weight: var(--heading-font-weight);
	}
	.typo-h4 {
		font-size: 52px;
		line-height: 60px;
		font-weight: var(--heading-font-weight);
	}
	.typo-h5 {
		font-size: 32px;
		line-height: 38px;
		font-weight: var(--heading-font-weight);
	}
	.typo-h6 {
		font-size: 24px;
		line-height: 32px;
		font-weight: var(--heading-font-weight);
	}

	/* Titles */
	.typo-t1 {
		font-size: 20px;
		line-height: 24px;
		font-weight: var(--title-font-weight);
	}
	.typo-t2 {
		font-size: 16px;
		line-height: 20px;
		font-weight: var(--title-font-weight);
	}
	.typo-t3 {
		font-size: 14px;
		line-height: 18px;
		font-weight: var(--title-font-weight);
	}
	.typo-t4 {
		font-size: 13px;
		line-height: 16px;
		font-weight: var(--title-font-weight);
	}
	.typo-t5 {
		font-size: 12px;
		line-height: 16px;
		font-weight: var(--title-font-weight);
	}

	/* Body text */
	.typo-b1 {
		font-size: 24px;
		line-height: 32px;
		font-weight: var(--body-font-weight);
	}
	.typo-b2 {
		font-size: 16px;
		line-height: 20px;
		font-weight: var(--body-font-weight);
	}
	.typo-b3 {
		font-size: 14px;
		line-height: 16px;
		font-weight: var(--body-font-weight);
	}
	.typo-b4 {
		font-size: 13px;
		line-height: 16px;
		font-weight: var(--body-font-weight);
	}
	.typo-b5 {
		font-size: 12px;
		line-height: 16px;
		font-weight: var(--body-font-weight);
	}
	.typo-b6 {
		font-size: 10px;
		line-height: 14px;
		font-weight: var(--body-font-weight);
	}
}
