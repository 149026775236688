/* Stats page -- some of it only works with !important bc of conflicting styles in the package :/ */

.tl-dashboards.public-dashboard-page {
	background-color: var(--main-bg) !important;
}

.tile {
	box-shadow: none !important;
}

.widget-visualization {
	background-color: var(--container-bg) !important;
	border: 1px solid var(--container-border) !important;
}

.visualization-renderer {
	overflow-y: hidden;
}

@media only screen and (max-width: 768px) {
	.widget-visualization,
	.dashboard-widget-wrapper {
		min-width: 90vw;
		margin-left: auto;
		margin-right: auto;
	}
	.tl-dashboard-container {
		position: absolute !important;
		left: 0 !important;
		align-content: center;
	}

	#footer {
		display: none !important;
	}
}

.visualization-name {
	color: var(--text-default) !important;
}

.query-link > span {
	font-size: 14px !important;
	color: var(--text-emphasis) !important;
	font-family: 'Marfa' !important;
}

.main-svg {
	background: transparent !important;
	color: var(--text-default) !important;
}

.main-svg > .infolayer > .legend > .bg {
	fill-opacity: 0 !important;
	background: transparent !important;
	color: var(--text-default) !important;
}

.main-svg
	> .infolayer
	> .legend
	> .scrollbox
	> .groups
	> .traces
	> .legendtext {
	fill: var(--text-default) !important;
	fill-opacity: 1 !important;
	font-family: 'Marfa' !important;
	color: var(--text-default);
}

.modebar,
.modebar-group {
	display: none;
}

.ygrid,
.xgrid {
	stroke: var(--text-label) !important;
	stroke-opacity: 0.5 !important;
}

/* Hide secondary chart lines for overlay graphs */
.cartesianlayer .subplot.xy .gridlayer .y2 {
	display: none;
}

.widget-text {
	background: transparent !important;
	border: none !important;
	font-family: 'Marfa' !important;
}

.markdown {
	background-color: var(--container-bg);
	border: 1px solid var(--container-border);
	align-content: center !important;
	text-align: center !important;
	font-family: 'Marfa' !important;
}

.markdown > h3 {
	font-size: 30px !important;
	color: var(--text-emphasis);
	align-content: center !important;
	vertical-align: middle !important;
	font-family: 'Marfa' !important;
}

.counter-visualization-value,
.counter-visualization-label {
	color: var(--text-emphasis);
	font-family: 'Marfa' !important;
}

.hidden-print > span {
	color: var(--text-default);
	font-family: 'Marfa' !important;
}

.main-svg > .hoverlayer > .hovertext > rect {
	display: none;
}

.main-svg > .hoverlayer > .hovertext > .name {
	display: none;
}

[data-widgetid='9628'] {
	.infolayer .legend > .scrollbox {
		transform: translate(15px, 0px);
	}
}
