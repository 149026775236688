.fold-and-fade-in {
	animation: foldAndFadeIn 300ms ease-out forwards;
}

@keyframes foldAndFadeIn {
	0% {
		opacity: 0;
		max-height: 0;
	}
	100% {
		opacity: 1;
		max-height: 300px;
	}
}

.fold-and-fade-out {
	animation: foldAndFadeOut 300ms ease-out forwards;
}

@keyframes foldAndFadeOut {
	0% {
		opacity: 1;
		max-height: 300px;
	}
	100% {
		opacity: 0;
		max-height: 0;
	}
}

.fade-in {
	animation: fadeIn 300ms linear forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.fade-out {
	animation: fadeOut 300ms linear forwards;
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.step-connector-fold {
	overflow: hidden;
	max-width: 0;
	transition: max-width 0.8s;
}

.step-connector-fold.open {
	max-width: 100px;
}

.onboarding-accordion-item {
	overflow: hidden;
	opacity: 1;
}

.onboarding-accordion-item-open {
	max-height: 500px;
	transition: max-height 0.5s ease-in;
}

.onboarding-accordion-item-closed {
	max-height: 0;
	transition: max-height 0.4s ease-out;
}

.onboarding-accordion-item-closed-fade {
	max-height: 0;
	opacity: 0;
	transition: max-height 0.4s ease-out, opacity 1.5s;
}


@keyframes driftStakingCoinLeftBg {
	0% {
		opacity: 0;
		transform: translateY(-20%);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
	}
}
.animation-drift-staking-coin-left-bg {
	animation: driftStakingCoinLeftBg 2s ease-out;
}

@keyframes driftStakingCoinLeft {
	0% {
		opacity: 0;
		transform: translateY(-30%);
	}
	80% {
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
	}
}
.animation-drift-staking-coin-left-fg {
	animation: driftStakingCoinLeft 2s cubic-bezier(.05,.62,.15,.99);
}


@keyframes driftStakingCoinRightBg {
	0% {
		opacity: 0;
		transform: translateY(-70%);
	}
	50% {
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
	}
}
.animation-drift-staking-coin-right-bg {
	animation: driftStakingCoinRightBg 2s ease-out;
}

@keyframes driftStakingCoinRight {
	0% {
		opacity: 0;
		transform: translateY(-50%);
	}
	80% {
		opacity: 1;
	}
	100% {
		transform: translateY(0%);
	}
}

.animation-drift-staking-coin-right-fg {
	animation: driftStakingCoinRight 2s cubic-bezier(.05,.62,.15,.99);
}
